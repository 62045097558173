import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

import { PostDetail } from "../components/AcfPostBlocks"

const Post = ({ location, data, pageContext }) => {
  const {
    title,
    content,
    uri,
    date,
    postsLayouts: { postObject },
    seo: { seoComponents },
  } = data.wpgraphql.post

  let seoComponent
  if (seoComponents.length > 0) {
    seoComponents.map(component => {
      switch (component.__typename) {
        case "WPGraphQL_Page_Seo_SeoComponents_SeoComponents": {
          seoComponent = component
          console.log("posts build SEO", seoComponent)
          break
        }
        default:
          console.log(component.__typename + " for SEO?")
      }
    })
  }

  if (
    !seoComponent ||
    seoComponent.seoTitle === null ||
    seoComponent.seoTitle === ""
  ) {
    seoComponent = { seoTitle: title }
  }

  return (
    <Layout
      seoComponent={seoComponent}
      location={location}
      canonicalPath={pageContext.canonicalPath}
    >
      {content && (
        <p className={title} dangerouslySetInnerHTML={{ __html: content }} />
      )}
      {postObject.length > 0 &&
        postObject.map((block, i) => {
          switch (block.__typename) {
            case "WPGraphQL_Post_Postslayouts_PostObject_PostDetail":
              return (
                <PostDetail
                  date={date}
                  key={`PostDetail-${i}`}
                  uri={uri}
                  pageContext={pageContext}
                  {...block}
                />
              )
            default:
              console.log("This is the End!", block.__typename)
              return "" // this should not happen
          }
        })}
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query GET_POST($postID: ID!) {
    wpgraphql {
      post(id: $postID) {
        title
        content
        uri
        date
        postsLayouts {
          postObject {
            __typename
            ...postDetailFragment
          }
        }
        seo {
          seoComponents {
            __typename
            ...postMetadataSectionFragment
          }
        }
      }
    }
  }
`
