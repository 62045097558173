import React, { useRef, useState } from "react"
import { graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import style from "./PostDetail.module.scss"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import LatestPosts from "../UI/LatestPosts"
import { Link } from "../Link"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Button, Overlay, Tooltip } from "react-bootstrap"
import he from "he"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import topQuote from "../../images/top.png"
import bottomQuote from "../../images/bottom.png"
import envelope from "../../images/social/envelope-dark.svg"
import fb from "../../images/social/facebook-dark.svg"
import link from "../../images/social/link-dark.svg"
import linkedin from "../../images/social/linkedin-dark.svg"
import twitter from "../../images/social/twitter-dark.svg"

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share"

import { StickyHelp } from "../UI/StickyHelp"

export const fragment = graphql`
  fragment postDetailFragment on WPGraphQL_Post_Postslayouts_PostObject_PostDetail {
    introduction
    sectionHeader
    shareText
    mainImage {
      ...GatsbyImageQuery
    }
    subheader {
      subheaderHeader
      subheaderDescription
      author
      quote
      subImage {
        ...GatsbyImageQuery
      }
      rightImage {
        ...GatsbyImageQuery
      }
      leftImage {
        ...GatsbyImageQuery
      }
    }
  }
`

const SharePost = ({
  shareText,
  url,
  flexDirection = "row",
  copyRef,
  showCopyMsgState,
}) => {
  const [showCopyMsg, setShowCopyMsg] = showCopyMsgState
  return (
    <aside
      className={`d-flex flex-${flexDirection} flex-wrap align-items-center my-4`}
    >
      {shareText ? (
        <span className="mr-3 font-weight-bold">{shareText}</span>
      ) : null}

      <div className={`d-flex flex-${flexDirection} ${style.shareBtns}`}>
        <FacebookShareButton className={style.shareBtn} url={url}>
          <img src={fb} alt="facebook icon" />
        </FacebookShareButton>
        <TwitterShareButton className={style.shareBtn} url={url}>
          <img src={twitter} alt="twitter icon" />
        </TwitterShareButton>
        <LinkedinShareButton className={style.shareBtn} url={url}>
          <img src={linkedin} alt="linkedin icon" />
        </LinkedinShareButton>
        <EmailShareButton className={style.shareBtn} url={url} borderRadius={0}>
          <img src={envelope} alt="email icon" />
        </EmailShareButton>

        <CopyToClipboard
          text={typeof window !== "undefined" ? window.location.href : null}
          onCopy={() => {
            setShowCopyMsg(true)
            setTimeout(() => setShowCopyMsg(false), 1000)
          }}
        >
          <Button
            ref={copyRef}
            variant="outline-secondary"
            className={style.shareBtn}
          >
            <img src={link} alt="copy link icon" />
          </Button>
        </CopyToClipboard>

        <Overlay target={copyRef.current} show={showCopyMsg} placement="bottom">
          {props => (
            <Tooltip id="copyTarget2" {...props}>
              Link copied!
            </Tooltip>
          )}
        </Overlay>
      </div>
    </aside>
  )
}

export const PostDetail = ({
         date,
         introduction,
         sectionHeader,
         shareText,
         mainImage,
         subheader,
         pageContext,
       }) => {
         const { post, origin, originPath, composedPath, categories } =
           pageContext || {}

         const url = `${process.env.SITE_URL}${composedPath}`

         let dt = new Date(date).toLocaleDateString("eng-GB", {
           year: "numeric",
           month: "long",
           day: "numeric",
         })

         const topShareCopyTarget = useRef(null)
         const bottomShareCopyTarget = useRef(null)
         const [showTopShareCopyMsg, setShowTopShareCopyMsg] = useState(false)
         const [showBottomShareCopyMsg, setShowBottomShareCopyMsg] = useState(
           false
         )

         return (
           <section className={style.section}>
             <div className="position-relative">
               <StickyHelp />

               <Container fluid={true} className={style.container}>
                 <Row>
                   <Col>
                     <div className="mb-4">
                       <FontAwesomeIcon
                         icon={faChevronLeft}
                         className={style.backIcon}
                       />
                       <a href={originPath} className={style.backBtn}>
                         Back
                       </a>
                     </div>

                     <span
                       dangerouslySetInnerHTML={{ __html: dt }}
                       className={style.dateText}
                     />
                     <br />
                     {origin === "news" && (
                       <div className="d-flex">
                         {categories &&
                           categories.map((category, index) => (
                             <Link
                               href={`${originPath}${category.slug}`}
                               className={style.breadcrumbCls}
                             >
                               {he.decode(category.name)}
                             </Link>
                           ))}
                       </div>
                     )}
                     <h1
                       dangerouslySetInnerHTML={{ __html: sectionHeader }}
                       className="h3 text-left my-3"
                     />
                     {mainImage && (
                       <div className="position-relative">
                         <div className={style.topShareBtns}>
                           <SharePost
                             url={url}
                             flexDirection="column"
                             copyRef={topShareCopyTarget}
                             showCopyMsgState={[
                               showTopShareCopyMsg,
                               setShowTopShareCopyMsg,
                             ]}
                           />
                         </div>

                         <img
                           src={mainImage.sourceUrl}
                           alt={mainImage.title}
                           className="mx-auto img-fluid w-100"
                         />
                       </div>
                     )}
                     {introduction && (
                       <div
                         dangerouslySetInnerHTML={{ __html: introduction }}
                         className={`${style.descriptionText} mt-3`}
                       />
                     )}

                     {subheader
                       ? subheader.map((subhead, i) => {
                           const {
                             author,
                             subheaderDescription,
                             subImage,
                             quote,
                             subheaderHeader,
                             rightImage,
                             leftImage,
                           } = subhead

                           return (
                             <>
                               <div
                                 key={`subhead-${i}`}
                                 className="position-relative"
                               >
                                 {subheaderHeader && (
                                   <h2
                                     className="h4"
                                     dangerouslySetInnerHTML={{
                                       __html: subheaderHeader,
                                     }}
                                   />
                                 )}
                                 {subImage && (
                                   <img
                                     src={subImage.sourceUrl}
                                     alt={subImage.title}
                                     className={style.subImage}
                                   />
                                 )}

                                 <div className={style.blockquoteContainer}>
                                   <Row className="flex-row-reverse">
                                     <Col xs={12}>
                                       <blockquote className="blockquote text-right my-3">
                                         <img
                                           src={topQuote}
                                           loading="lazy"
                                           alt="top-quote"
                                           className="mb-4"
                                         />
                                         {quote && (
                                           <p
                                             className={style.quoteText}
                                             dangerouslySetInnerHTML={{
                                               __html: quote,
                                             }}
                                           />
                                         )}
                                         {author && (
                                           <footer
                                             className={`${style.blockquoteFooter} blockquote-footer mb-4 `}
                                             dangerouslySetInnerHTML={{
                                               __html: author,
                                             }}
                                           />
                                         )}
                                         <img
                                           src={bottomQuote}
                                           loading="lazy"
                                           alt="bottom-Quote"
                                         />
                                       </blockquote>
                                     </Col>
                                   </Row>
                                 </div>

                                 <div className="position-relative">
                                   {subheaderDescription && (
                                     <div
                                       className={style.subheaderText}
                                       dangerouslySetInnerHTML={{
                                         __html: subheaderDescription,
                                       }}
                                     />
                                   )}
                                 </div>

                                 <div
                                   className={`d-flex flex-column ${style.twoImg}`}
                                 >
                                   {leftImage && (
                                     <img
                                       src={leftImage.sourceUrl}
                                       alt={leftImage.title}
                                       className={style.pictures}
                                     />
                                   )}
                                   {rightImage && (
                                     <img
                                       src={rightImage.sourceUrl}
                                       alt={rightImage.title}
                                       className={style.pictures}
                                     />
                                   )}
                                 </div>
                               </div>
                             </>
                           )
                         })
                       : null}
                     <div className={style.postEnd}>
                       <SharePost
                         url={url}
                         shareText={shareText}
                         copyRef={bottomShareCopyTarget}
                         showCopyMsgState={[
                           showBottomShareCopyMsg,
                           setShowBottomShareCopyMsg,
                         ]}
                       />
                     </div>
                   </Col>
                 </Row>
               </Container>
             </div>

             <LatestPosts
               sectionHeader={"Latest News"}
               buttonLink={"/news"}
               buttonText={"View all news"}
             />
           </section>
         )
       }
